<template>
  <div id="single-site-dashboard" class="site-container">
    <div v-if="isLoading" class="loading-screen">
      <div class="loading-container">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </div>
    </div>
    <template v-else>
      <div v-if="dataSingleSite" class="wp-single-site">
        <div class="single-site-general">
          <div class="col-12">
            <div class="row">
              <div class="col-4 customize-col-4 customize--left">
                <div class="site-information-form">
                  <div class="site-information-content">
                    <div class="site-information-detail">
                      <div class="detail-desc">
                        <div class="row-info-space">
                          <p v-if="dataSingleSite" class="project-address">{{ dataSingleSite.global }} / {{ dataSingleSite.regional }} / {{ dataSingleSite.area }} / {{ dataSingleSite.project }}</p>
                        </div>
                        <div class="row-info-space">
                          <p class="detail-desc-title">SITE ID</p>
                          <p class="detail-desc-content">{{ dataSingleSite.id ? dataSingleSite.id : '' }}</p>
                        </div>
                        <div class="row-info-space">
                          <p class="detail-desc-title">SITE NAME</p>
                          <p class="detail-desc-content">{{ dataSingleSite.site_name ? dataSingleSite.site_name : '' }}</p>
                        </div>
                        <div class="row-info-space">
                          <p class="detail-desc-title">COMMISSIONED DATE</p>
                          <p class="detail-desc-content">{{ dataSingleSite.commissioned_date ? MOMENT(dataSingleSite.commissioned_date, "DD/MM/YYYY") : '' }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="site-information-weather">
                  <div class="information-weather-wrapper">
                    <VueWeatherComponent title="Weather" units="ca" :currentDay="currentDay" :forecast="forecast" :enable-credits="false">
                    </VueWeatherComponent>
                    <div class="weather-status-today">
                      <v-tooltip v-if="currentDay?.description?.length > 15" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <p 
                            v-bind="attrs"
                            v-on="on"
                            class="high-status"
                            style="cursor: pointer"
                          >
                            {{ truncateWithEllipsis(15, currentDay.description) }}</p>
                        </template>
                        <span>{{ capitalizeFirstLetter(currentDay.description) }}</span>
                      </v-tooltip>
                      <p v-else class="high-status">{{ currentDay?.description ? capitalizeFirstLetter(currentDay.description) : '' }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-8 customize-col-8 customize--right">
                <div class="information-boxes-wrapper">
                  <div class="information-box-heading">
                    <h2 class="information-box-heading-title">COST & SAVING SUMMARY</h2>
                  </div>
                  <div class="information-box-content-wrapper">
                    <div class="information-box-container">
                      <div class="information-box-width">
                        <div class="information-box-outline total-col-4">
                          <div class="information-box-fixed">
                            <div class="information-box-group">
                              <div class="info-img">
                                <img src="@/assets/pages/single-site/general-1.2.svg" />
                              </div>
                              <div class="info-desc">
                                <div class="info-data">
                                  <strong class="info-num">{{ dataSingleSite.saving_summary?.today_expense ? convertBahtCurrency(dataSingleSite.saving_summary.today_expense) : '0' }}</strong>
                                  <span class="info-unit-more">{{ dataSingleSite.saving_summary?.today_expense ? convertCountingUnit(dataSingleSite.saving_summary.today_expense) : '' }}</span>
                                  <span class="info-currency">
                                    {{ "\u0e3f" }}
                                  </span>
                                </div>
                                <p class="info-total">Today Expense</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="information-box-width">
                        <div class="information-box-outline total-col-4">
                          <div class="information-box-fixed">
                            <div class="information-box-group">
                              <div class="info-img">
                                <img src="@/assets/pages/single-site/general-2.2.svg" />
                              </div>
                              <div class="info-desc">
                                <div class="info-data">
                                  <strong class="info-num">{{ dataSingleSite.saving_summary?.today_saving ? convertBahtCurrency(dataSingleSite.saving_summary.today_saving) : '0' }}</strong>
                                  <span class="info-unit-more">{{ dataSingleSite.saving_summary?.today_saving ? convertCountingUnit(dataSingleSite.saving_summary.today_saving) : '' }}</span>
                                  <span class="info-currency">
                                    {{ "\u0e3f" }}
                                  </span>
                                </div>
                                <p class="info-total">Today Saving</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="information-box-width">
                        <div class="information-box-outline total-col-4">
                          <div class="information-box-fixed">
                            <div class="information-box-group">
                              <div class="info-img">
                                <img src="@/assets/pages/single-site/general-3.2.svg" />
                              </div>
                              <div class="info-desc">
                                <div class="info-data">
                                  <strong class="info-num">{{ dataSingleSite.saving_summary?.lifetime_saving ? convertBahtCurrency(dataSingleSite.saving_summary.lifetime_saving) : '0' }}</strong>
                                  <span class="info-unit-more">{{ dataSingleSite.saving_summary?.lifetime_saving ? convertCountingUnit(dataSingleSite.saving_summary.lifetime_saving) : '' }}</span>
                                  <span class="info-currency">
                                    {{ "\u0e3f" }}
                                  </span>
                                </div>
                                <p class="info-total">Lifetime Saving</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="information-box-width">
                        <div class="information-box-outline total-col-4">
                          <div class="information-box-fixed">
                            <div class="information-box-group">
                              <div class="info-img">
                                <img src="@/assets/pages/single-site/general-4.2.svg" />
                              </div>
                              <div class="info-desc">
                                <div class="info-data info-data-green">
                                  <template v-if="dataSingleSite.saving_summary?.electricity_trend !== null && dataSingleSite.saving_summary?.electricity_percent !== null">
                                    <span class="down-arrow">
                                      <img v-if="dataSingleSite.saving_summary.electricity_trend === 'increment'" src="@/assets/pages/single-site/arrow-up-red.svg" />
                                      <img v-else src="@/assets/pages/single-site/arrow-down-green.svg" />
                                    </span>
                                    <strong class="info-num">{{ formatDecimalNumber(dataSingleSite.saving_summary.electricity_percent) }}</strong>
                                    <span class="percent-text">%</span>
                                  </template>
                                  <strong v-else class="info-num">N/A</strong>
                                </div>
                                <p class="info-total">Current Electricity Trend</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="information-box-width">
                        <div class="information-box-outline total-col-4">
                          <div class="information-box-fixed">
                            <div class="information-box-group">
                              <div class="info-img">
                                <img src="@/assets/pages/single-site/general-5.2.svg" />
                              </div>
                              <div class="info-desc">
                                <div class="info-data">
                                  <strong class="info-num">{{ dataSingleSite.saving_summary?.today_co2_saving ? convertCo2Value(dataSingleSite.saving_summary.today_co2_saving) : '0' }}</strong>
                                  <span class="info-unit">{{ dataSingleSite.saving_summary?.today_co2_saving ? convertCo2Unit(dataSingleSite.saving_summary.today_co2_saving) : 'kg' }}</span>
                                </div>
                                <p class="info-total">Today CO2 Saving</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="information-box-width">
                        <div class="information-box-outline total-col-4">
                          <div class="information-box-fixed">
                            <div class="information-box-group">
                              <div class="info-img">
                                <img src="@/assets/pages/single-site/general-6.2.svg" />
                              </div>
                              <div class="info-desc">
                                <div class="info-data">
                                  <strong class="info-num">{{ dataSingleSite.saving_summary?.lifetime_co2_saving ? convertCo2Value(dataSingleSite.saving_summary.lifetime_co2_saving) : '0' }}</strong>
                                  <span class="info-unit">{{ dataSingleSite.saving_summary?.lifetime_co2_saving ? convertCo2Unit(dataSingleSite.saving_summary.lifetime_co2_saving) : 'kg' }}</span>
                                </div>
                                <p class="info-total">Lifetime CO2 Saving</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="single-site-general single-site-general--space">
          <div class="col-12">
            <div class="row">
              <div class="col-4 customize-col-4 customize--left">
                <div class="total-sites-chart total-sites-alarm total-sites-chart--background">
                  <div class="total-sites-chart-heading">
                    <h2 class="total-sites-chart--title">ALARM SUMMARY</h2>
                  </div>
                  <div class="col-12 total-sites-chart-content total-sites-chart-content--padding-alarm">
                    <div class="row">
                      <div class="col-6 p-0 col-6-left-chart">
                        <div class="donut-chart">
                          <VueApexCharts
                            v-if="series.length > 0" 
                            type="donut" 
                            :options="chartOptions" 
                            :series="series"
                          />
                          <div class="total-sites-title">
                            <strong class="title-desc-num">{{ formatCommasAndDecimalForNumber(dataSingleSite.alarm_summary.total) }}</strong>
                            <p v-if="series.length > 0" class="title-desc-name">Active Alarm</p>
                          </div>
                        </div>
                      </div>
                      <div class="col-6 p-0 col-6-right-chart">
                        <div class="alarm-summary-legend">
                          <div class="alarm-summary-legend-left">
                            <div class="sites-legend">
                              <span class="circle green-circle"></span>
                              <div class="sites-legend-desc">
                                <strong class="legend-desc-num">{{ dataSingleSite.alarm_summary.count.critical }}</strong>
                                <p class="legend-desc-status">Critical</p>
                              </div>
                            </div>
                            <div class="sites-legend">
                              <span class="circle yellow-circle"></span>
                              <div class="sites-legend-desc">
                                <strong class="legend-desc-num">{{ dataSingleSite.alarm_summary.count.major }}</strong>
                                <p class="legend-desc-status">Major</p>
                              </div>
                            </div>
                          </div>
                          <div class="alarm-summary-legend-right">
                            <div class="sites-legend">
                              <span class="circle grey-circle"></span>
                              <div class="sites-legend-desc">
                                <strong class="legend-desc-num">{{ dataSingleSite.alarm_summary.count.minor }}</strong>
                                <p class="legend-desc-status">Minor</p>
                              </div>
                            </div>
                            <div class="sites-legend">
                              <span class="circle circle-normal"></span>
                              <div class="sites-legend-desc">
                                <strong class="legend-desc-num" style="visibility: hidden">0</strong>
                                <p class="legend-desc-status">Normal</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="total-sites-chart total-sites-energy total-sites-chart--background">
                  <div class="total-sites-chart-heading">
                    <h2 class="total-sites-chart--title">ENERGY SUMMARY</h2>
                  </div>
                  <div class="total-sites-chart-content total-sites-chart-content--padding-energy">
                    <div class="total-saving-info-group total-saving-info--space">
                      <div class="info-img">
                        <img src="@/assets/pages/single-site/energy-1.2.svg" />
                      </div>
                      <div class="info-desc">
                        <div class="info-data">
                          <strong class="info-num">{{ convertEnergyValue(dataSingleSite.energy_summary.today_pv_energy) }}</strong>
                          <span class="info-unit">{{ convertEnergyUnit(dataSingleSite.energy_summary.today_pv_energy) }}</span>
                        </div>
                        <p class="info-total">Today</p>
                      </div>
                      <div class="info-desc">
                        <div class="info-data">
                          <strong class="info-num">{{ convertEnergyValue(dataSingleSite.energy_summary.lifetime_pv_energy) }}</strong>
                          <span class="info-unit">{{ convertEnergyUnit(dataSingleSite.energy_summary.lifetime_pv_energy) }}</span>
                        </div>
                        <p class="info-total">Lifetime</p>
                      </div>
                    </div>
                    <div class="total-saving-info-group">
                      <div class="info-img">
                        <img src="@/assets/pages/single-site/energy-2.2.svg" />
                      </div>
                      <div class="info-desc">
                        <div class="info-data">
                          <strong class="info-num">{{ convertEnergyValue(dataSingleSite.energy_summary.today_consumed_energy) }}</strong>
                          <span class="info-unit">{{ convertEnergyUnit(dataSingleSite.energy_summary.today_consumed_energy) }}</span>
                        </div>
                        <p class="info-total">Today</p>
                      </div>
                      <div class="info-desc">
                        <div class="info-data">
                          <strong class="info-num">{{ convertEnergyValue(dataSingleSite.energy_summary.lifetime_consumed_energy) }}</strong>
                          <span class="info-unit">{{ convertEnergyUnit(dataSingleSite.energy_summary.lifetime_consumed_energy) }}</span>
                        </div>
                        <p class="info-total">Lifetime</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-8 customize-col-8 customize--right border-radius-8 total-sites-chart--background">
                <div class="site-monitoring-wrapper">
                  <div class="site-monitoring-heading">
                    <h2 class="site-monitoring-heading--title">SITE MONITORING</h2>
                  </div>
                  <div class="site-monitoring-house">
                    <!-- House model image -->
                    <img class="house-model-img" src="https://pana-sempapi.antk.co/api/images/smarthome.png" alt="House Model">

                    <!-- Text boxes grey line -->
                    <img class="box-grey-line" :src="home_load_grey_line" alt="Home Load Line">
                    <img class="box-grey-line" :src="ev_charger_grey_line" :style="`${dataSingleSite?.site_monitoring?.evse_power ? '' : 'visibility: hidden'}`" alt="EV Charger Line">
                    <img class="box-grey-line" :src="solar_pv_grey_line" :style="`${dataSingleSite?.site_monitoring?.pv_power ? '' : 'visibility: hidden'}`" alt="Solar PV Line">
                    <img class="box-grey-line" :src="grid_grey_line" alt="Grid Line">
                    <img class="box-grey-line" :src="battery_grey_line" :style="`${dataSingleSite?.site_monitoring?.bess_power ? '' : 'visibility: hidden'}`" alt="Battery Line">

                    <!-- Home Load Text Box -->
                    <div class="overlay-box home-load-box bg-color-transparent">
                      <div class="info-desc text-left">
                        <div class="info-data">
                          <template v-if="dataSingleSite?.site_monitoring?.house_load_power?.energy !== null && dataSingleSite?.site_monitoring?.house_load_power?.energy !== undefined && ['online', 'normal'].includes(dataSingleSite?.site_monitoring?.house_load_power?.status)">
                            <strong class="info-num">{{ formatDecimalNumber(dataSingleSite.site_monitoring.house_load_power.energy) }}</strong>
                            <span class="info-unit">kW</span>
                          </template>
                          <template v-else>
                            <strong class="info-num">−−</strong>
                          </template>
                        </div>
                        <p class="info-total">HOME</p>
                      </div>
                    </div>

                    <!-- Solar PV Text Box -->
                    <div class="overlay-box solar-pv-box bg-color-transparent" :style="`${dataSingleSite?.site_monitoring?.pv_power ? '' : 'visibility: hidden'}`">
                      <div class="info-desc text-right">
                        <div class="info-data">
                          <template v-if="dataSingleSite?.site_monitoring?.pv_power?.is_regular_alarm && ['offline'].includes(dataSingleSite?.site_monitoring?.pv_power?.status)">
                            <strong class="info-num">0</strong>
                            <span class="info-unit">kW</span>
                          </template>
                          <template v-else-if="dataSingleSite?.site_monitoring?.pv_power?.energy !== null && dataSingleSite?.site_monitoring?.pv_power?.energy !== undefined && ['online', 'normal'].includes(dataSingleSite?.site_monitoring?.pv_power?.status)">
                            <strong class="info-num">{{ formatDecimalNumber(dataSingleSite.site_monitoring.pv_power.energy) }}</strong>
                            <span class="info-unit">kW</span>
                          </template>
                          <template v-else>
                            <strong class="info-num">−−</strong>
                          </template>
                        </div>
                        <p class="info-total">SOLAR PV</p>
                      </div>
                    </div>

                    <!-- EV Charger Text Box -->
                    <div class="overlay-box overlay-box-ev ev-charger-box bg-color-transparent" :style="`${dataSingleSite?.site_monitoring?.evse_power ? '' : 'visibility: hidden'}`">
                      <div class="info-desc text-right">
                        <div class="info-data">
                          <template v-if="dataSingleSite?.site_monitoring?.evse_power?.energy !== null 
                            && dataSingleSite?.site_monitoring?.evse_power?.energy !== undefined 
                            && ['online', 'normal'].includes(dataSingleSite?.site_monitoring?.evse_power?.status)
                            && (dataSingleSite?.site_monitoring?.house_load_power?.energy ?? -1) >= 0"
                          >
                            <strong class="info-num">{{ formatDecimalNumber(dataSingleSite.site_monitoring.evse_power.energy) }}</strong>
                            <span class="info-unit">kW</span>
                          </template>
                          <template v-else>
                            <strong class="info-num">−−</strong>
                          </template>
                        </div>
                        <p class="info-total">EV CHARGER</p>
                      </div>
                    </div>
                    
                    <!-- Battery Text Box -->
                    <div class="overlay-box battery-box bg-color-transparent" :style="`${dataSingleSite?.site_monitoring?.bess_power ? '' : 'visibility: hidden'}`">
                      <div class="info-desc text-right">
                        <div class="info-data">
                          <template v-if="dataSingleSite?.site_monitoring?.bess_power?.energy !== null && dataSingleSite?.site_monitoring?.bess_power?.energy !== undefined && ['online', 'normal'].includes(dataSingleSite?.site_monitoring?.bess_power?.status)">
                            <strong class="info-num">{{ formatDecimalNumber(dataSingleSite.site_monitoring.bess_power.energy) }}</strong>
                            <span class="info-unit">kW</span>
                          </template>
                          <template v-else>
                            <strong class="info-num">−−</strong>
                          </template>
                        </div>
                        <template v-if="dataSingleSite?.site_monitoring?.bess_power?.energy !== null && dataSingleSite?.site_monitoring?.bess_power?.energy !== undefined && ['online', 'normal'].includes(dataSingleSite?.site_monitoring?.bess_power?.status)">
                          <p class="info-total">BATTERY {{ dataSingleSite?.site_monitoring?.bess_power?.battery_percent ? `(${dataSingleSite.site_monitoring.bess_power.battery_percent}%)` : '' }}</p>
                          <p style="color: #00FF78;" v-if="formatDecimalNumber(dataSingleSite.site_monitoring.bess_power.energy) == 0" class="info-total">STANDBY</p>
                          <p style="color: #00FF78;" v-else-if="formatDecimalNumber(dataSingleSite.site_monitoring.bess_power.energy) < 0" class="info-total">CHARGING</p>
                          <p style="color: #00FF78;" v-else class="info-total">DISCHARGING</p>
                        </template>
                        <template v-else>
                          <p class="info-total">BATTERY {{ dataSingleSite?.site_monitoring?.bess_power?.battery_percent ? `(${dataSingleSite.site_monitoring.bess_power.battery_percent}%)` : '' }}</p>
                        </template>
                      </div>
                    </div>

                    <!-- Grid Text Box -->
                    <div class="overlay-box grid-box bg-color-transparent">
                      <div class="info-desc text-left">
                        <div class="info-data">
                          <template v-if="dataSingleSite?.site_monitoring?.grid_power?.energy !== null && dataSingleSite?.site_monitoring?.grid_power?.energy !== undefined && ['online', 'normal'].includes(dataSingleSite?.site_monitoring?.grid_power?.status)">
                            <strong class="info-num">{{ formatDecimalNumber(dataSingleSite.site_monitoring.grid_power.energy) }}</strong>
                            <span class="info-unit">kW</span>
                          </template>
                          <template v-else>
                            <strong class="info-num">−−</strong>
                          </template>
                        </div>
                        <template v-if="dataSingleSite?.site_monitoring?.grid_power?.energy !== null && dataSingleSite?.site_monitoring?.grid_power?.energy !== undefined && ['online', 'normal'].includes(dataSingleSite?.site_monitoring?.grid_power?.status)">
                          <p class="info-total">GRID</p>
                          <p style="color: #00FF78;" v-if="formatDecimalNumber(dataSingleSite.site_monitoring.grid_power.energy) < 0" class="info-total">EXPORT</p>
                          <p style="color: #00FF78;" v-else class="info-total">IMPORT</p>
                        </template>
                        <template v-else>
                          <p class="info-total">GRID</p>
                        </template>
                      </div>
                    </div>

                    <!-- Planted Equivalently Text Box -->
                    <div class="overlay-box planted-equivalently-box bg-color-transparent">
                      <div class="info-desc text-left">
                        <div class="info-data">
                          <template v-if="dataSingleSite?.site_monitoring?.tree !== null && dataSingleSite?.site_monitoring?.tree !== undefined">
                            <strong class="info-num">{{ formatCommasAndDecimalForNumber(dataSingleSite.site_monitoring.tree) }}</strong>
                            <span class="info-unit">Trees</span>
                          </template>
                          <template v-else>
                            <strong class="info-num">−−</strong>
                          </template>
                        </div>
                        <p class="info-total">PLANTED EQUIVALENTLY</p>
                      </div>
                    </div>
                    
                    <!-- House Devices With Status -->
                    <template v-if="dataSingleSite?.pv_installed">
                      <!-- pv online and operating: -->
                      <img 
                        v-if="['online', 'normal'].includes(dataSingleSite?.site_monitoring?.pv_power?.status) && dataSingleSite?.site_monitoring?.pv_power?.energy !== 0" 
                        class="absolute-device-power" 
                        :src="house_pv_on" 
                        alt="PV online and operating"
                      />
                      <!-- pv online and idle: -->
                      <img 
                        v-else-if="['online', 'normal'].includes(dataSingleSite?.site_monitoring?.pv_power?.status) && dataSingleSite?.site_monitoring?.pv_power?.energy === 0"
                        class="absolute-device-power" 
                        :src="house_pv_idle_online" 
                        alt="PV online and idle"
                      />
                      <!-- pv online and error: -->
                      <img 
                        v-else-if="['alarm'].includes(dataSingleSite?.site_monitoring?.pv_power?.status)" 
                        class="absolute-device-power" 
                        :src="house_pv_error_online" 
                        alt="PV online and error"
                      />
                      <!-- pv offline: -->
                      <img 
                        v-else
                        class="absolute-device-power" 
                        :src="house_pv_off" 
                        alt="PV offline"
                      />
                    </template>
                    <template v-if="dataSingleSite?.evse_installed">
                      <!-- ev online and operating: -->
                      <img 
                        v-if="['online', 'normal'].includes(dataSingleSite?.site_monitoring?.evse_power?.status) && dataSingleSite?.site_monitoring?.evse_power?.energy !== 0"
                        class="absolute-device-power" 
                        :src="house_ev_on" 
                        alt="EV Charger online and operating"
                      />
                      <!-- ev online and idle: -->
                      <img 
                        v-else-if="['online', 'normal'].includes(dataSingleSite?.site_monitoring?.evse_power?.status) && dataSingleSite?.site_monitoring?.evse_power?.energy === 0"
                        class="absolute-device-power" 
                        :src="house_ev_idle_online" 
                        alt="EV Charger online and idle"
                      />
                      <!-- ev online and error: -->
                      <img 
                        v-else-if="['alarm'].includes(dataSingleSite?.site_monitoring?.evse_power?.status)" 
                        class="absolute-device-power" 
                        :src="house_ev_error_online" 
                        alt="EV Charger online and error"
                      />
                      <!-- ev offline: -->
                      <img
                        v-else 
                        class="absolute-device-power" 
                        :src="house_ev_off" 
                        alt="EV Charger offline"
                      />
                    </template>
                    <template v-if="dataSingleSite?.bess_installed">
                      <!-- battery online and operating: -->
                      <img 
                        v-if="['online', 'normal'].includes(dataSingleSite?.site_monitoring?.bess_power?.status) && dataSingleSite?.site_monitoring?.bess_power?.energy !== 0"
                        class="absolute-device-power" 
                        :src="house_battery_on" 
                        alt="Battery online and operating"
                      />
                      <!-- battery online and idle: -->
                      <img 
                        v-else-if="['online', 'normal'].includes(dataSingleSite?.site_monitoring?.bess_power?.status) && dataSingleSite?.site_monitoring?.bess_power?.energy === 0" 
                        class="absolute-device-power" 
                        :src="house_battery_idle_online" 
                        alt="Battery online and idle"
                      />
                      <!-- battery online and error: -->
                      <img 
                        v-else-if="['alarm'].includes(dataSingleSite?.site_monitoring?.bess_power?.status)"
                        class="absolute-device-power" 
                        :src="house_battery_error_online" 
                        alt="Battery online and error"
                      />
                      <!-- battery offline: -->
                      <img
                        v-else 
                        class="absolute-device-power" 
                        :src="house_battery_off" 
                        alt="Battery offline"
                      />
                    </template>
                    <template v-if="dataSingleSite?.pv_installed">
                      <!-- pv panel online and operating: -->
                      <img 
                        v-if="['online', 'normal'].includes(dataSingleSite?.site_monitoring?.pv_power?.status) && dataSingleSite?.site_monitoring?.pv_power?.energy !== 0"
                        class="absolute-device-power" 
                        :src="house_pv_panel_on" 
                        alt="PV Panel online and operating"
                      />
                      <!-- pv panel online and idle: -->
                      <img 
                        v-else-if="['online', 'normal'].includes(dataSingleSite?.site_monitoring?.pv_power?.status) && dataSingleSite?.site_monitoring?.pv_power?.energy === 0" 
                        class="absolute-device-power" 
                        :src="house_pv_panel_idle_online" 
                        alt="PV Panel online and idle"
                      />
                      <!-- pv panel online and error: -->
                      <img 
                        v-else-if="['alarm'].includes(dataSingleSite?.site_monitoring?.pv_power?.status)"  
                        class="absolute-device-power" 
                        :src="house_pv_panel_error_online" 
                        alt="PV Panel online and error"
                      />
                      <!-- pv panel offline: -->
                      <img
                        v-else 
                        class="absolute-device-power" 
                        :src="house_pv_panel_off" 
                        alt="PV Panel offline">
                    </template>

                    <!-- House Devices Power Line With Status -->
                    <template v-if="dataSingleSite?.pv_installed">
                      <!-- Panel and PV Inverter power line on: -->
                      <img 
                        v-if="['online', 'normal'].includes(dataSingleSite?.site_monitoring?.pv_power?.status) && 
                          dataSingleSite?.site_monitoring?.pv_power?.energy > 0
                        "
                        class="absolute-device-power" 
                        :src="panel_pv_power_on" 
                        alt="Panel PV power on"
                      />
                      <!-- Panel and PV Inverter power line off: -->
                      <img 
                        v-else
                        class="absolute-device-power" 
                        :src="panel_pv_power_off" 
                        alt="Panel PV power off"
                      />
                    </template>
                    <template v-if="dataSingleSite?.pv_installed && dataSingleSite?.bess_installed">
                      <!-- Battery and PV Inverter power line on: -->
                      <img 
                        v-if="['online', 'normal'].includes(dataSingleSite?.site_monitoring?.pv_power?.status) && 
                          ['online', 'normal'].includes(dataSingleSite?.site_monitoring?.bess_power?.status) && 
                          dataSingleSite?.site_monitoring?.bess_power?.energy !== 0
                        "
                        class="absolute-device-power" 
                        :src="battery_pv_power_on" 
                        alt="Battery PV power on"
                      />
                      <!-- Battery and PV Inverter power line off: -->
                      <img 
                        v-else
                        class="absolute-device-power" 
                        :src="battery_pv_power_off" 
                        alt="Battery PV power off"
                      />
                    </template>
                    <template v-if="dataSingleSite?.pv_installed">
                      <!-- PV Inverter and Grid power line on: -->
                      <img 
                        v-if="['online', 'normal'].includes(dataSingleSite?.site_monitoring?.grid_power?.status) && 
                          ['online', 'normal'].includes(dataSingleSite?.site_monitoring?.pv_power?.status) &&
                          dataSingleSite?.site_monitoring?.grid_power?.energy !== 0
                        "
                        class="absolute-device-power" 
                        :src="pv_grid_power_on" 
                        alt="PV Grid power on"
                      />
                      <!-- PV Inverter and Grid power line off: -->
                      <img 
                        v-else
                        class="absolute-device-power" 
                        :src="pv_grid_power_off" 
                        alt="PV Grid power off"
                      />
                    </template>
                    <template v-if="dataSingleSite?.evse_installed">
                      <!-- EV Charger power line on: -->
                      <img 
                        v-if="['online', 'normal'].includes(dataSingleSite?.site_monitoring?.evse_power?.status) && 
                          dataSingleSite?.site_monitoring?.evse_power?.energy > 0
                        "
                        class="absolute-device-power" 
                        :src="ev_charger_power_on" 
                        alt="EV power on"
                      />
                      <!-- EV Charger power line off: -->
                      <img 
                        v-else
                        class="absolute-device-power" 
                        :src="ev_charger_power_off" 
                        alt="EV power off"
                      />
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<style lang="scss" src="@/assets/css/single-site-dashboard.scss"></style>

<script>
import VueApexCharts from "vue-apexcharts";
import VueWeatherComponent from 'vue-weather-component';
import { singleSiteService } from "@/services";
import moment from "moment";

const home_load_grey_line = require("@/assets/pages/single-site/home_load_grey_line.png");
const ev_charger_grey_line = require("@/assets/pages/single-site/ev_charger_grey_line.png");
const solar_pv_grey_line = require("@/assets/pages/single-site/solar_pv_grey_line.png");
const grid_grey_line = require("@/assets/pages/single-site/grid_grey_line.png");
const battery_grey_line = require("@/assets/pages/single-site/battery_grey_line.png");

const house_pv_on = require("@/assets/pages/single-site/house_pv_on.png");
const house_ev_on = require("@/assets/pages/single-site/house_ev_on.png");
const house_battery_on = require("@/assets/pages/single-site/house_battery_on.png");
const house_pv_panel_on = require("@/assets/pages/single-site/house_pv_panel_on.png");

const house_pv_idle_online = require("@/assets/pages/single-site/house_pv_idle_online.png");
const house_ev_idle_online = require("@/assets/pages/single-site/house_ev_idle_online.png");
const house_battery_idle_online = require("@/assets/pages/single-site/house_battery_idle_online.png");
const house_pv_panel_idle_online = require("@/assets/pages/single-site/house_pv_panel_idle_online.png");

const house_pv_error_online = require("@/assets/pages/single-site/house_pv_error_online.png");
const house_ev_error_online = require("@/assets/pages/single-site/house_ev_error_online.png");
const house_battery_error_online = require("@/assets/pages/single-site/house_battery_error_online.png");
const house_pv_panel_error_online = require("@/assets/pages/single-site/house_pv_panel_error_online.png");

const house_pv_off = require("@/assets/pages/single-site/house_pv_off.png");
const house_ev_off = require("@/assets/pages/single-site/house_ev_off.png");
const house_battery_off = require("@/assets/pages/single-site/house_battery_off.png");
const house_pv_panel_off = require("@/assets/pages/single-site/house_pv_panel_off.png");

const panel_pv_power_on = require("@/assets/pages/single-site/panel_pv_power_on.png");
const battery_pv_power_on = require("@/assets/pages/single-site/battery_pv_power_on.png");
const pv_grid_power_on = require("@/assets/pages/single-site/pv_grid_power_on.png");
const ev_charger_power_on = require("@/assets/pages/single-site/ev_charger_power_on.png");

const panel_pv_power_off = require("@/assets/pages/single-site/panel_pv_power_off.png");
const battery_pv_power_off = require("@/assets/pages/single-site/battery_pv_power_off.png");
const pv_grid_power_off = require("@/assets/pages/single-site/pv_grid_power_off.png");
const ev_charger_power_off = require("@/assets/pages/single-site/ev_charger_power_off.png");

export default {
  name: "",
  components: { VueApexCharts, VueWeatherComponent, },
  data() {
    return {
      home_load_grey_line: home_load_grey_line,
      ev_charger_grey_line: ev_charger_grey_line,
      solar_pv_grey_line: solar_pv_grey_line,
      grid_grey_line: grid_grey_line,
      battery_grey_line: battery_grey_line,
      house_pv_on: house_pv_on,
      house_ev_on: house_ev_on,
      house_battery_on: house_battery_on,
      house_pv_panel_on: house_pv_panel_on,
      house_pv_idle_online: house_pv_idle_online,
      house_ev_idle_online: house_ev_idle_online,
      house_battery_idle_online: house_battery_idle_online,
      house_pv_panel_idle_online: house_pv_panel_idle_online,
      house_pv_error_online: house_pv_error_online,
      house_ev_error_online: house_ev_error_online,
      house_battery_error_online: house_battery_error_online,
      house_pv_panel_error_online: house_pv_panel_error_online,
      house_pv_off: house_pv_off,
      house_ev_off: house_ev_off,
      house_battery_off: house_battery_off,
      house_pv_panel_off: house_pv_panel_off,
      panel_pv_power_on: panel_pv_power_on,
      battery_pv_power_on: battery_pv_power_on,
      pv_grid_power_on: pv_grid_power_on,
      ev_charger_power_on: ev_charger_power_on,
      panel_pv_power_off: panel_pv_power_off,
      battery_pv_power_off: battery_pv_power_off,
      pv_grid_power_off: pv_grid_power_off,
      ev_charger_power_off: ev_charger_power_off,
      isLoading: true,
      series: [],
      chartOptions: {
        chart: {
          type: 'donut',
        },
        tooltip: {
          enabled: true,
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          show: false,
        },
        plotOptions: {
          pie: {
            donut: {
              size: '75%'
            }
          }
        },
        colors: ['#BE5108', '#C7930B', '#2D6CA4'],
        labels: ["Critical", "Major", "Minor"],
        stroke: {
          show: false
        }
      },
      dataSingleSite: null,
      currentDay: {
        icon: 'clear-day',
        temp: '18',
        currentSummary: 'Fog.',
        hourlySummary: '3.6 mm',
        windSpeed: 6
      },
      forecast: [{
          name: 'Tue',
          icon: 'partly-cloudy-day',
          highTemp: '37',
          lowTemp: '32'
      }, {
          name: 'Wed',
          icon: 'fog',
          highTemp: '34',
          lowTemp: '29'
      }, {
          name: 'Thu',
          icon: 'cloudy',
          highTemp: '34',
          lowTemp: '29'
      }, {
          name: 'Fri',
          icon: 'rain',
          highTemp: '33',
          lowTemp: '25'
      }, {
          name: 'Sat',
          icon: 'snow',
          highTemp: '-9',
          lowTemp: '-20'
      }],
      dataWeather: null,
      testDataWea: null,
      testCurrentWea: null,
      intervalGetData: null,
      socketData: null,
      heartbeatInterval: null,
      isBeforeDestroy: false,
    };
  },
  computed: {},
  async created() {
    this.isLoading = true;
    await this.getDataSingleSite();
    await this.getDataWeather();
    this.handleListWeather();
    this.forecast = this.testDataWea;
    this.currentDay = this.testCurrentWea;
    this.drawingDonutChart();
    this.isLoading = false;
  },
  mounted() {
    this.getDataSingleSite();
    this.initializeWebSocket();
  },
  destroyed() {
    clearInterval(this.intervalGetData);
  },
  methods: {
    initializeWebSocket() {
      if (!this.isBeforeDestroy) {
        const url = `${this.WEB_SOCKET_URL}?key=${this.X_API_KEY}&type=web`;
        this.socketData = new WebSocket(url);
        this.socketData.onmessage = (event) => {
          const socketEventData = JSON.parse(event.data);
          if (socketEventData.action === "get_single_site_monitoring_summary" && socketEventData.data.id == this.$store.state.siteId) {
            this.dataSingleSite = { ...this.dataSingleSite, ...socketEventData.data };
          }
          if (["get_energy_and_saving_single_site", "get_alarm_single_site"].includes(socketEventData.action) && socketEventData.data.site_id == this.$store.state.siteId) {
            this.dataSingleSite = { ...this.dataSingleSite, ...socketEventData.data };
          }
        };
        this.socketData.onopen = () => {
          this.startHeartbeat();
        };
        this.socketData.onclose = () => {
          this.stopHeartbeat();
          //Try to reconnect after a delay
          setTimeout(() => {
            this.initializeWebSocket();
          }, 5000);
        };
      }
    },
    startHeartbeat() {
      this.heartbeatInterval = setInterval(() => {
        if (this.socketData && this.socketData.readyState === WebSocket.OPEN) {
          this.socketData.send(JSON.stringify({ action: 'heartbeat' }));
        }
      }, 30000); //Send heartbeat every 30 seconds
    },
    stopHeartbeat() {
      if (this.heartbeatInterval) {
        clearInterval(this.heartbeatInterval);
        this.heartbeatInterval = null;
      }
    },
    handleVisibilityChange() {
      if (document.hidden) {
        if (this.socketData) {
          this.socketData.close();
        }
        this.stopHeartbeat();
      } else {
        this.initializeWebSocket();
      }
    },
    async getDataSingleSite(){
      const singleId = this.$store.state.siteId ? this.$store.state.siteId : null;
      if(singleId) {
        await singleSiteService
          .getSingleSite(singleId)
          .then((res) => {
            if (res.status == 200 && res?.data?.data) {
              this.dataSingleSite = res.data.data;
            } else {
              throw res;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    handleListWeather() {
      if (!localStorage.getItem('data_weather_one_call_storage')) {
        localStorage.setItem('data_weather_one_call_storage', JSON.stringify(this.dataWeather));
      }
      if (this.dataWeather?.daily) {
        let weatherCurrent = this.dataWeather.current;
        let weatherList = this.dataWeather.daily;
        let dailyWeatherData = {};
        let currentDate = moment();
        let currentDateStr = currentDate.format('YYYY-MM-DD');
        let forecastData = [];
        let dataCurrent = {};

        if (!dataCurrent.icon) {
          dataCurrent.icon = this.WEATHER_ICONS[weatherCurrent.weather[0].icon];
        }
        if (!dataCurrent.description) {
          dataCurrent.description = weatherCurrent.weather[0].description;
        }
        if (!dataCurrent.currentSummary) {
          dataCurrent.currentSummary = (weatherCurrent.temp - 273.15).toFixed(0) + " °C";
        }
        if (!dataCurrent.windSpeed && weatherCurrent.wind_speed) {
          dataCurrent.windSpeed = weatherCurrent.wind_speed;
        }

        let daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        let sortedDays = daysOfWeek.slice(currentDate.day()).concat(daysOfWeek.slice(0, currentDate.day()));

        for (let i = 0; i < 7; i++) {
          let item = weatherList[i];
          let itemDate = moment(item.dt * 1000);
          let dayOfWeek = sortedDays[i];
          item.dayOfWeek = dayOfWeek;
          let itemDateStr = itemDate.format('YYYY-MM-DD');
          if (itemDateStr === currentDateStr) {
            if (!dailyWeatherData[currentDateStr]) {
              dailyWeatherData[currentDateStr] = item;
            } else {
              if (itemDate.hour() > moment(dailyWeatherData[currentDateStr].dt * 1000).hour()) {
                dailyWeatherData[currentDateStr] = item;
              }
            }
          } else {
            currentDate.add(1, 'day');
            currentDateStr = currentDate.format('YYYY-MM-DD');
            dailyWeatherData[currentDateStr] = item;
          }

          if (!dataCurrent.hourlySummary && item.rain) {
            dataCurrent.hourlySummary = item.rain + " mm";
          }

          let highTempCelsius = (item.temp.max - 273.15).toFixed(0);
          let lowTempCelsius = (item.temp.min - 273.15).toFixed(0);
          forecastData.push({
            name: dayOfWeek,
            icon: this.WEATHER_ICONS[item.weather[0].icon],
            highTemp: highTempCelsius,
            lowTemp: lowTempCelsius,
          });
        }
        this.testDataWea = forecastData;
        this.testCurrentWea = dataCurrent;
      }
    },
    drawingDonutChart () {
      this.series = Object.values(this.dataSingleSite.alarm_summary.count).map(value => value);
      if (!this.series.some(element => element !== 0)) {
        this.series.push(1);
        this.chartOptions.tooltip.enabled = false;
        this.chartOptions.colors.push("#4A8521");
        this.chartOptions.labels.push("Normal");
      }
    },
    async getDataWeather () {
      let data_weather_storage = localStorage.getItem('data_weather_one_call_storage') ? JSON.parse(localStorage.getItem('data_weather_one_call_storage')) : null;
      if (data_weather_storage) {
        this.dataWeather = data_weather_storage;
      } else {
        let lat = parseFloat(this.dataSingleSite.latitude);
        let long = parseFloat(this.dataSingleSite.longitude);
        await singleSiteService
          .getDataWeather(lat, long)
          .then((res) => {
            if (res.status == 200 && res?.data) {
              localStorage.setItem('data_weather_one_call_storage', JSON.stringify(res.data));
              this.dataWeather = localStorage.getItem('data_weather_one_call_storage') ? JSON.parse(localStorage.getItem('data_weather_one_call_storage')) : null;
            } else {
              throw res;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
  beforeDestroy() {
    this.isBeforeDestroy = true;
    if (this.socketData) {
      this.socketData.close();
    }
    this.stopHeartbeat();
    document.removeEventListener('visibilitychange', this.handleVisibilityChange);
  },
};
</script>
